import React from "react";
import { Link } from "react-router-dom";
import VideoModal from "../../components/ModalVideo";

const About = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className={`wpo-about-area section-padding ${props.abClass}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={props.abimg} alt="" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-about-title">
                <span>Despre noi</span>
                <h2>
                  Oferim servicii profesionale de arhitectura, design si
                  urbanism
                </h2>
              </div>
              <h5>Ajutam fiecare client sa isi atinga visele</h5>
              <p>
                CDD DINAMIC IMOBINVEST este o firma de proiectare din Oradea
                formata din 3 frati cu aceeasi pasiune pentru arhitectura :
                GHILEA Ionut Cristian, GHILEA Dan-Marius si GHILEA Dora-Maria.
                Firma a fost infiintata in anul 2022 propunandu-si sa evolueze
                constant si sa isi puna amprenta din ce in ce mai mult asupra
                mediului construit pornind de la nivelul programului de locuire,
                pana la programe destinate turismului, locuirii colective si
                urbanismului. Filozofia biroului de proiectare consta in
                creearea arhitecturii impreuna si in concordanta cu nevoile
                clientului, propunandu-si astfel ca fiecare obiect arhitectural
                sa fie in final un produs personalizat, unic, performant din
                punct de vedere energetic, prezent in context si care reuseste
                sa imbogateasca mediul in care este amplasat.
              </p>
              {/* <div className="btns">
                <ul>
                  <li className="video-holder">
                    <VideoModal />
                  </li>
                  <li className="video-text">Vezi clipul nostru</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
