import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import About from "../../components/about";
import ProjectSection from "../../components/Projects";
import ServiceSection from "../../components/Services";
import FunFact from "../../components/FunFact";
import Testimonial from "../../components/Testimonial";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/main/logo1.png";
import abimg from "../../images/about.png";

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle={"Despre noi"} pagesub={"Despre"} />
      <About abimg={abimg} />
      <ServiceSection />
      {/* <FunFact fnClass={"wpo-fun-fact-section-s2"} />
      <Testimonial /> */}
      <Footer ftClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
