import React, { Component } from "react";
import Slider from "react-slick";
import SectionTitle from "../SectionTitle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from "../../images/testimonial/img-1.jpg";
import ts2 from "../../images/testimonial/img-2.jpg";
import ts3 from "../../images/testimonial/img-3.jpg";

class Testimonial extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const testimonial = [
      {
        tsImg: ts1,
        Des: "“Ullamcorper a at sit et. Augue non donec dui quis tellus ullamcorper vitae. Eleifend augue sed diam malesuada urna, porttitor lorem dolor.”",
        Title: "Name 1",
        Sub: "Position 1",
      },
      {
        tsImg: ts2,
        Des: "“Ullamcorper a at sit et. Augue non donec dui quis tellus ullamcorper vitae. Eleifend augue sed diam malesuada urna, porttitor lorem dolor.”",
        Title: "Name 2",
        Sub: "Position 2",
      },
      {
        tsImg: ts3,
        Des: "“Ullamcorper a at sit et. Augue non donec dui quis tellus ullamcorper vitae. Eleifend augue sed diam malesuada urna, porttitor lorem dolor.”",
        Title: "Name 3",
        Sub: "Position 3",
      },
    ];
    return (
      <div className="wpo-testimonial-area section-padding">
        <div className="container">
          <SectionTitle
            subTitle={"Testimonial"}
            MainTitle={"People Say About Us"}
          />
          <div className="wpo-testimonial-wrap">
            <div className="testimonial-slider owl-carousel">
              <Slider {...settings}>
                {testimonial.map((tesmnl, tsm) => (
                  <div className="wpo-testimonial-item" key={tsm}>
                    <div className="wpo-testimonial-top">
                      <div className="wpo-testimonial-img">
                        <img src={tesmnl.tsImg} alt="" />
                      </div>
                      <div className="wpo-testimonial-info">
                        <h2>{tesmnl.Title}</h2>
                        <span>{tesmnl.Sub}</span>
                      </div>
                    </div>
                    <div className="wpo-testimonial-content">
                      <p>{tesmnl.Des}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
