import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Hero2 from "../../components/hero2";
import About2 from "../../components/about2";
import ServiceSection from "../../components/Services";
import Testimonial from "../../components/Testimonial";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/main/logo1.png";

const HomePage2 = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <Hero2 />
      <About2 />
      <ServiceSection sClass={"section-bg"} />
      {/* <Testimonial /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage2;
