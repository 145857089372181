import timg1 from "../images/team/img1.jpeg";
import timg2 from "../images/team/img2.jpeg";
import timg3 from "../images/team/img3.jpeg";

const Teams = [
  {
    Id: "ghilea-ionut-cristian",
    tImg: timg1,
    name: "Ghilea Ionut Cristian",
    title: "Arhitect",
  },
  {
    Id: "ghilea-dan-marius",
    tImg: timg2,
    name: "Ghilea Dan-Marius",
    title: "Arhitect",
  },
  {
    Id: "ghilea-dora-maria",
    tImg: timg3,
    name: "Ghilea Dora-Maria",
    title: "Arhitect",
  },
];

export default Teams;
