import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/main/logo2.png";
// import Projects from "../../api/project";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className={`wpo-site-footer ${props.ftClass}`}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            {/* <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"> */}
            <div className="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link onClick={ClickHandler} className="logo" to="/">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
                <p>Va ajutam sa va indepliniti visele</p>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col col-xl-4  col-lg-4 col-md-6 col-sm-12 col-12"> */}
            <div className="col col-xl-6  col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact </h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>Str. General
                      Nicolae Sova, nr. 13/A
                      <br /> Oradea, Romania
                    </li>
                    <li>
                      <i className="fi flaticon-telephone"></i>+ 40 (359) 439
                      564
                      <br />
                    </li>
                    <li>
                      <i className="fi flaticon-email"></i>
                      cddimob@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col col-xl-4  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget instagram">
                {/* <div className="widget-title">
                  <h3>Our Gallery</h3>
                </div> */}
                {/* <ul className="d-flex">
                  {Projects.slice(0, 6).map((project, pot) => (
                    <li key={pot}>
                      <Link
                        onClick={ClickHandler}
                        to={`/project-single/${project.Id}`}>
                        <img src={project.pImg} alt="" />
                      </Link>
                    </li>
                  ))}
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <ul>
                <li>
                  &copy; Design By{" "}
                  <Link onClick={ClickHandler} to="/">
                    DaGeMa
                  </Link>
                  . All Rights Reserved.
                </li>
                {/* <li>
                  <Link onClick={ClickHandler} to="/">
                    Terms of use |
                  </Link>{" "}
                  <Link onClick={ClickHandler} to="/">
                    Privacy Environmental Policy
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
