import React from "react";
import { Link } from "react-router-dom";
import VideoModal from "../../components/ModalVideo";
import abimg from "../../images/about2.jpeg";
import abimg2 from "../../images/about3.jpeg";

const About2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className="wpo-about-area-s2 section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <div className="wpo-about-img-left">
                <img
                  src={abimg}
                  alt=""
                  style={{ maxWidth: "350px", maxHeight: "460px" }}
                />
                {/* <div className="wpo-about-img-text">
                  <h2>7+</h2>
                  <p>Ani de experienta</p>
                </div> */}
              </div>
              <div className="wpo-about-img-right">
                <img
                  src={abimg2}
                  alt=""
                  style={{ maxWidth: "350px", maxHeight: "460px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-about-title">
                <span>Despre noi</span>
                <h2>
                  Oferim servicii profesionale de arhitectura, design si
                  urbanism
                </h2>
              </div>
              <p>
                CDD DINAMIC IMOBINVEST este o firma de proiectare din Oradea
                formata din 3 frati cu aceeasi pasiune pentru arhitectura :
                GHILEA Ionut Cristian, GHILEA Dan-Marius si GHILEA Dora-Maria.
                Firma a fost infiintata in anul 2022 propunandu-si sa evolueze
                constant si sa isi puna amprenta din ce in ce mai mult asupra
                mediului construit pornind de la nivelul programului de locuire,
                pana la programe destinate turismului, locuirii colective si
                urbanismului. Filozofia biroului de proiectare consta in
                creearea arhitecturii impreuna si in concordanta cu nevoile
                clientului, propunandu-si astfel ca fiecare obiect arhitectural
                sa fie in final un produs personalizat, unic, performant din
                punct de vedere energetic, prezent in context si care reuseste
                sa imbogateasca mediul in care este amplasat.
              </p>

              <div className="btns">
                <Link to="/despre" onClick={ClickHandler} className="theme-btn">
                  Descopera mai multe
                </Link>
                {/* <ul>
                  <li className="video-holder">
                    <VideoModal />
                  </li>
                  <li className="video-text">Vezi clipul nostru</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About2;
