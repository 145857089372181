import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from "../../images/main/picture1.png";
import hero2 from "../../images/main/picture2.png";
import hero3 from "../../images/main/picture3.png";
import hero4 from "../../images/main/picture4.png";
import hero5 from "../../images/main/picture5.jpg";
import hero6 from "../../images/main/picture6.jpg";
import hero7 from "../../images/main/picture7.jpeg";

class Hero2 extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      fade: true,
    };

    return (
      <section className="wpo-box-style">
        <div
          className={`wpo-hero-slider wpo-hero-style-2 ${this.props.heroClass}`}>
          <div className="hero-container">
            <div className="hero-wrapper">
              <Slider {...settings}>
                <div className="hero-slide">
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${hero1})` }}>
                    <div className="container-fluid">
                      <div className="slide-content">
                        <div className="slide-title">
                          <h2>Indeplinim vise</h2>
                        </div>
                        <div className="slide-title-sub">
                          <p>Va ajutam sa va indepliniti visele</p>
                        </div>
                        <div className="clearfix"></div>
                        <div className="slide-btns">
                          <Link to="/despre" className="theme-btn">
                            Descopera mai multe
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-slide">
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${hero2})` }}>
                    <div className="container-fluid">
                      <div className="slide-content">
                        <div className="slide-title">
                          <h2>Indeplinim vise</h2>
                        </div>
                        <div className="slide-title-sub">
                          <p>Va ajutam sa va indepliniti visele</p>
                        </div>
                        <div className="clearfix"></div>
                        <div className="slide-btns">
                          <Link to="/despre" className="theme-btn">
                            Descopera mai multe
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-slide">
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${hero4})` }}>
                    <div className="container-fluid">
                      <div className="container-fluid">
                        <div className="slide-content">
                          <div className="slide-title">
                            <h2>Indeplinim vise</h2>
                          </div>
                          <div className="slide-title-sub">
                            <p>Va ajutam sa va indepliniti visele</p>
                          </div>
                          <div className="clearfix"></div>
                          <div className="slide-btns">
                            <Link to="/despre" className="theme-btn">
                              Descopera mai multe
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-slide">
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${hero3})` }}>
                    <div className="container-fluid">
                      <div className="container-fluid">
                        <div className="slide-content">
                          <div className="slide-title">
                            <h2>Indeplinim vise</h2>
                          </div>
                          <div className="slide-title-sub">
                            <p>Va ajutam sa va indepliniti visele</p>
                          </div>
                          <div className="clearfix"></div>
                          <div className="slide-btns">
                            <Link to="/despre" className="theme-btn">
                              Descopera mai multe
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-slide">
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${hero4})` }}>
                    <div className="container-fluid">
                      <div className="container-fluid">
                        <div className="slide-content">
                          <div className="slide-title">
                            <h2>Indeplinim vise</h2>
                          </div>
                          <div className="slide-title-sub">
                            <p>Va ajutam sa va indepliniti visele</p>
                          </div>
                          <div className="clearfix"></div>
                          <div className="slide-btns">
                            <Link to="/despre" className="theme-btn">
                              Descopera mai multe
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-slide">
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${hero5})` }}>
                    <div className="container-fluid">
                      <div className="container-fluid">
                        <div className="slide-content">
                          <div className="slide-title">
                            <h2>Indeplinim vise</h2>
                          </div>
                          <div className="slide-title-sub">
                            <p>Va ajutam sa va indepliniti visele</p>
                          </div>
                          <div className="clearfix"></div>
                          <div className="slide-btns">
                            <Link to="/despre" className="theme-btn">
                              Descopera mai multe
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-slide">
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${hero6})` }}>
                    <div className="container-fluid">
                      <div className="container-fluid">
                        <div className="slide-content">
                          <div className="slide-title">
                            <h2>Indeplinim vise</h2>
                          </div>
                          <div className="slide-title-sub">
                            <p>Va ajutam sa va indepliniti visele</p>
                          </div>
                          <div className="clearfix"></div>
                          <div className="slide-btns">
                            <Link to="/despre" className="theme-btn">
                              Descopera mai multe
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-slide">
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${hero7})` }}>
                    <div className="container-fluid">
                      <div className="container-fluid">
                        <div className="slide-content">
                          <div className="slide-title">
                            <h2>Indeplinim vise</h2>
                          </div>
                          <div className="slide-title-sub">
                            <p>Va ajutam sa va indepliniti visele</p>
                          </div>
                          <div className="clearfix"></div>
                          <div className="slide-btns">
                            <Link to="/despre" className="theme-btn">
                              Descopera mai multe
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero2;
