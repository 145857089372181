import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage2 from "../HomePage2";
import AboutPage from "../AboutPage";
import ProjectPage from "../ProjectPage";
import TeamSinglePage from "../TeamSinglePage";
import TestimonialPage from "../TestimonialPage";
import ContactPage from "../ContactPage";
import TeamPage from "../TeamPage";
import CareerPage from "../CareerPage";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Homepage2 />} />
          <Route path="despre" element={<AboutPage />} />
          <Route path="echipa" element={<TeamPage />} />
          <Route path="echipa/angajat/:id" element={<TeamSinglePage />} />
          <Route path="proiecte" element={<ProjectPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="cariera" element={<CareerPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
